import React, { memo } from "react";
import { ResponsivePie } from '@nivo/pie';
import { getColor, pieChartProps } from "./InsightsHelper";

const PieChart = ({ dataPoints }) => {
    return (
        <ResponsivePie
            data={dataPoints}
            innerRadius={0.65}
            padAngle={0.7}
            cornerRadius={0}
            colors={getColor}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            enableRadialLabels={false}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            enableSlicesLabels={false}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={false}
            enableLabel={false}
            defs={pieChartProps.defs}
            fill={pieChartProps.fills}
        />)
}

export default memo(PieChart);
