import React, { Component } from 'react';
import appConfig from '../../../Environment/environments';
import axiosInstance from '../Interceptor/interceptor';
import './SearchWithSuggestions.scss';
import { GET_CARCGL_INTERNAL_USER_NAME, SEARCHSUGGESTIONSERROR, GETINTERNALUSERNAME, COMPANYNAMEPLACEHOLDER } from '../../../Common/Constants/constants';
import { encryptData , injectIntlTranslation} from "../../../Common/Utilities/utility";
import { intlShape, injectIntl } from "react-intl";
import CompanyLogo from '../../../Assests/Images/Carrier.svg'; //Import Company Logo

class SearchWithSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      results: [],
      selectedItem: '',
      selectedItemEmail: '',
      selectedItemFname: '',
      selectedItemLname: '',
      showUsersList: false,
      error: false,
      errorMessage: "",
    }
  }

  isNullOrUndefined(data) {
    return ((data === '' || data === " " || data === null || data === "null" || data === undefined) ? true :false);
  }

  handleClose = (selectedIndex) => {
    if (this.isNullOrUndefined(this.state.results[selectedIndex].email))
    {
      this.setState({
        selectedItem: (this.isNullOrUndefined(this.state.results[selectedIndex].name) ?
          this.state.results[selectedIndex].email :
          this.state.results[selectedIndex].name
        ),
        selectedItemEmail: this.state.results[selectedIndex].email,
        selectedItemFname: this.state.results[selectedIndex].fName,
        selectedItemLname: this.state.results[selectedIndex].lName,
        showUsersList: false,
        error: true,
        errorMessage: SEARCHSUGGESTIONSERROR
      });
    }
    else
    {
      this.setState({
        selectedItem: (this.isNullOrUndefined(this.state.results[selectedIndex].name) ?
          this.state.results[selectedIndex].email :
          this.state.results[selectedIndex].name         
        ),
        selectedItemEmail: this.state.results[selectedIndex].email,
        selectedItemFname: this.state.results[selectedIndex].fName,
        selectedItemLname: this.state.results[selectedIndex].lName,
        showUsersList: false,
        error: false,
        errorMessage: ""
      }, () => {
        this.props.getSelectedUserInfo(this.props.isCarCgl, this.state.selectedItem, this.state.selectedItemEmail, 
          this.state.results[selectedIndex].userPrincipalName, this.state.results[selectedIndex].fName, 
          this.state.results[selectedIndex].lName)
      });
    }
  }

  getInfo = () => {
    let url = `${appConfig.api.development}${(this.props.isCarCgl) ? GET_CARCGL_INTERNAL_USER_NAME : GETINTERNALUSERNAME}${encryptData(this.state.query).toString()}`
    axiosInstance.get(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        if (res) {
          this.setState({
            results: res.data
          })
        }
      })
      .catch(error => {
        console.log("error:", error)
      })
  }

  handleInputChange = () => {
    this.setState({
      query: this.search.value, selectedItem: this.search.value, showUsersList: true
    }, () => {
      if (this.state.query !== "" && this.state.query.length > 0) {
        this.getInfo();
      }
      else if (this.state.query === "") {
        this.setState({
          showUsersList: false,
          selectedItem: '',
          selectedItemEmail: '',
          selectedItemFname: '',
          selectedItemLname: '',
          error: false,
          errorMessage: ""
        }, () => {
          this.props.getSelectedUserInfo(this.props.isCarCgl, this.state.selectedItem, this.state.selectedItemEmail, this.state.selectedItemFname, this.state.selectedItemLname);
        })
      }
    })
  }

  blurHandler = (event)=> {
    this.setState({
      showUsersList: false
    })
  } 

  render() {
    const { intl } = this.props;
    return (
      <form>
        <div className="internalUserInput">
          <img src={CompanyLogo} className="internalUserLogo"/>
        <input
          type="text"
          className={(this.state.error) ? "invalidInternalUserTextField" : "internalUserTextField"}
          ref={input => this.search = input}
          onChange={this.handleInputChange}
          onBlur={this.blurHandler}
          value={this.state.selectedItem}
          style={{ padding: "0px"}}
          autoFocus={(this.props.autoFocus) ? false : true }
          placeholder= {injectIntlTranslation(intl, COMPANYNAMEPLACEHOLDER)}
        />
        {(this.state.error) && <div className="errorText">{this.state.errorMessage}</div>}
        {
         (this.state.results.length > 0 && this.state.showUsersList === true) ?
          <div className="usersList" tabIndex={1} >
            <ul className="internalUsersInfo">{
              this.state.results.map((r,index) => (
              <li className="userDetails" key={index} onMouseDown={this.handleClose.bind(this,index)}>
                  <div title={r.name} className="internalUserName">{r.name}</div>
                  <div title={r.email} className="internalUserEmail">{r.email}</div>
              </li>
            ))}</ul>
          </div> : null
        }
        </div>
      </form>
    )
  }
}

SearchWithSuggestions.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(SearchWithSuggestions)