import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from '@material-ui/icons/Warning';
import ClearIcon from '@material-ui/icons/Clear';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import axiosInstance from '../../Shared/Interceptor/interceptor';
import ApplyAllGroups from '../../Shared/ApplyAllGroups/ApplyAllGroups';
import appConfig from '../../../Environment/environments';
import ToastMessages from '../../Shared/Toast/ToastMessages'
import "../addGroup/addgroup.scss";
import { getGroupsData } from "../GetGroupsDataHelper";
import {
  LOADINGTEXT, MOVEUSERAPI, USERALREADYEXISTERROR, USERMOVEDSUCCESS, NOGROUPSFOUND, AddGroupStyles,
  SAMEPARENTSELECT, SAMEGROUPASPARENTSELECT, CANNOTSELECTCHILDASPARENTGROUPERROR, CHANGEGROUP,
  PARENTGROUPCHANGEAPI, RQRGROUP, SAVETEXT, GROUPNAMES, SEARCHERROR, ENTER, GROUPSEARCH, ADDGROUPDISABLEMSG
} from '../../../Common/Constants/constants';
import '../../Shared/SearchWithSuggestions/SearchWithSuggestions.scss';

class ChangeGroup extends React.Component {
  state = {
    statusMessage: false,
    message: "",
    toastVPosition: 'top',
    toastHPosition: 'right',
    data: [],
    parentSelectionError: "",
    selectedGroup: {
      value: this.props.selectedGroupId || '',
      label: this.props.selectedGroupName || ''
    },
    messageVariant: '',
    loading: true,
    searchText: '',
    showSuggestion: false,
    suggestions: [],
    filteredSuggestions: [],
    isChildrenDisplayed: false,
    noMatch: false,
    searchPressed: false,
    isSelectedGroupDisable: true
  }

  componentDidMount = () => getGroupsData(this.getChildrenSuccess, this.getChildrenFailure);

  componentWillUnmount = () => this.setState({ data: [] })

  getChildrenSuccess = (responseData) => this.setState({ data: responseData, loading: false })

  getChildrenFailure = (error) => this.setState({ loading: false })

  handleToast(reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ statusMessage: false });
  }

  onSubmitHandler = (e) => {
    e.preventDefault();
    const { callApiOnSave, userId = "", saveType = "changeGroup", GroupId, PrevParentGroupId = "", onParentChange } = this.props;
    const { selectedGroup } = this.state;
    const parentId = selectedGroup.value || "";
    const groupId = GroupId || "";
    if (parentId === "") {
      this.setState({ parentSelectionError: RQRGROUP });
    } else {
      let error = '';
      let showError = false;
      if (parentId === groupId) {
        showError = true;
        error = SAMEGROUPASPARENTSELECT
      }
      if (parentId === PrevParentGroupId) {
        showError = true;
        error = SAMEPARENTSELECT
      }
      if (showError) {
        this.setState({
          statusMessage: true,
          message: error,
          messageVariant: "error"
        });
      }
      else {
        if (callApiOnSave) {
          switch (saveType) {
            case "moveUser":
              const moveUserObj = {
                newGroupID: parentId,
                UserID: userId,
                OldGroupID: groupId,
                IsManager: (this.props.gridType == 0) ? true : false
              };
              this.saveParent(moveUserObj, MOVEUSERAPI, this.moveParentSuccess, this.moveParentFailure);
              break;
            default:
              const changegroupObj = {
                parentGroupID: parentId,
                GroupID: groupId
              };
              this.saveParent(changegroupObj, PARENTGROUPCHANGEAPI, this.changeParentSuccess, this.changeParentFailure);
              break;
          }
        }
        else {
          onParentChange && onParentChange(selectedGroup);
        }
      }
    }
  }

  moveParentSuccess = (responseData) => {
    const { onSave, onClose } = this.props;
    this.setState({
      statusMessage: true,
      message: USERMOVEDSUCCESS,
      messageVariant: "success"
    });
    onSave && onSave();
    onClose && onClose();
  }

  moveParentFailure = () => this.showErrorToastMsg(USERALREADYEXISTERROR)

  changeParentSuccess = (responseData) => this.props.onParentChange(responseData)

  changeParentFailure = () => this.showErrorToastMsg(CANNOTSELECTCHILDASPARENTGROUPERROR)

  showErrorToastMsg = (msg) =>
    this.setState({
      statusMessage: true,
      message: msg,
      messageVariant: "error"
    })

  saveParent = async (apiObj, apiUrl, successCallback, failureCallback) => {
    try {
      const updatedObj = JSON.stringify(apiObj);
      const saveChangeData = await axiosInstance.post(`${appConfig.api.development}${apiUrl}`, updatedObj, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      successCallback && successCallback(saveChangeData.data);
    }
    catch (error) {
      if (error.response.data != undefined && error.response.data.message.indexOf("Maximum") > -1) {
        this.showErrorToastMsg(error.response.data.message);
      } else
        failureCallback && failureCallback();
    }
  }

  saveSelectedGroups = (selectedGroup) => {    
    this.setState({ selectedGroup: selectedGroup, parentSelectionError: null, isSelectedGroupDisable: selectedGroup.isActive });
  }

  showGroups = () =>
    this.state.data.map((ChildGroup, index) =>
      <ApplyAllGroups isChildrenDisplayed={this.state.isChildrenDisplayed} type={1} key={index} TreeLevel={0} groupData={ChildGroup} saveSelectedGroups={this.saveSelectedGroups} selectedGroups={this.state.selectedGroup.value} searchText={this.state.searchText} updateSearch={this.updateSearch} searchPressed={this.state.searchPressed} />
    )

  updateSearch = (data) => {
    this.setState({ searchPressed: data })
  }

  onChangeOfSearchText = (e) => {
    this.setState({ searchText: e.target.value, showSuggestion: true }, () => {
      if (this.state.searchText.length === 1 && this.state.suggestions.length === 0) {
        axiosInstance.get(`${appConfig.api.development}${GROUPNAMES}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        })
          .then(response => {
            this.setState({ suggestions: response.data });
            this.filterSuggestions();
          });
      }
      if (this.state.searchText.length === 0) {
        this.setState({ isChildrenDisplayed: false })
      }
      this.filterSuggestions();
    });
  }

  filterSuggestions = () => {
    const suggestions = this.state.suggestions;
    if (suggestions.length !== 0) {
      const filteredSuggestions = suggestions.filter(group =>
        group.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
      this.state.searchText && (filteredSuggestions.length === 0 ? this.setState({ noMatch: true }) : this.setState({ noMatch: false }));
      this.setState({ filteredSuggestions: filteredSuggestions })
    }
  }

  onClearSearch = () => {
    this.state.searchText.length > 0 && getGroupsData(this.getChildrenSuccess, this.getChildrenFailure);
    this.setState({ searchText: '', showSuggestion: false, isChildrenDisplayed: false })
  }

  onSelectSuggestion = (selectedIndex) => {
    this.setState({ searchText: this.state.filteredSuggestions[selectedIndex], showSuggestion: false })
  }

  blurHandler = (event) => {
    this.setState({
      showSuggestion: false
    })
  }

  onSearchClick = () => {
    if (this.state.searchText && !this.state.noMatch) {
      let obj = {
        "Query": this.state.searchText,
        "IsAllInfo": false,
        "Type": 1
      };
      let data = JSON.stringify(obj);
      axiosInstance.post(`${appConfig.api.development}${GROUPSEARCH}`, data,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }).then(response => {
          response.data.length > 0 ? this.setState({ data: response.data, isChildrenDisplayed: true, showSuggestion: false, searchPressed: true }) : this.setState({ noMatch: true });
        });
    }
  }

  render() {
    const { root, changeGroupsListContainer, changeGroupRootSettings, loadingChangeGroup, updatebutton, paperSize, margin, saveButton, container, editContent, editGroupPaper, editTitle, editGroupTitle, closeIcon, GroupSearchBox, groupSuggestion } = this.props.classes;
    const { headerLabel = CHANGEGROUP, onClose, children } = this.props;
    return (
      <div className={root}>
        <div>
          <Paper square={true} classes={{ root: paperSize, elevation1: editGroupPaper }}>
            <div className={editTitle}>
              <p className={editGroupTitle}>{headerLabel}
                <span className={closeIcon} onClick={onClose}>
                  <EditClose></EditClose>
                </span>
              </p>
            </div>
            <div className={editContent}>
              <div className={container} >
                <div className="errorText">{this.state.parentSelectionError}</div>
                {this.state.selectedGroup && !this.state.isSelectedGroupDisable &&
                  <span className="addGroupDisableMsg">
                    <span style={{verticalAlign:'middle'}}><WarningIcon></WarningIcon></span>                          
                    <span>{ADDGROUPDISABLEMSG}</span>                   
                  </span>
                }  
                {(this.state.data.length > 0)
                  ?
                  <>
                    {children || null}
                    <div className={changeGroupsListContainer}>
                      <div className={GroupSearchBox}>
                        <TextField
                          onChange={this.onChangeOfSearchText}
                          onKeyDown={(e) => {
                            if (e.key === ENTER) {
                              this.onSearchClick();
                            }
                          }}
                          onBlur={this.blurHandler}
                          value={this.state.searchText}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment>
                                <IconButton>
                                  <SearchIcon onClick={this.onSearchClick} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment>
                                <IconButton>
                                  <ClearIcon onClick={this.onClearSearch} />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: "searchBox"
                          }}
                        />
                        {this.state.noMatch && this.state.searchText && <div className={"searchError"}>{SEARCHERROR}</div>}
                        {
                          (this.state.showSuggestion === true && this.state.searchText.length > 0 && this.state.filteredSuggestions.length > 0) ?
                            <div className="groupSuggestionList" tabIndex={1} >
                              <ul className="internalUsersInfo">
                                {
                                  this.state.filteredSuggestions.map((group, index) => (
                                    <li className={groupSuggestion} key={index} onMouseDown={() => this.onSelectSuggestion(index)}>
                                      <div className="internalUserName">{group}</div>
                                    </li>))
                                }
                              </ul>
                            </div> : null
                        }
                      </div>

                      <div className={changeGroupRootSettings}>{this.showGroups()}</div>
                    </div>
                  </> :
                  <p className={loadingChangeGroup}>
                    {(this.state.loading) ? LOADINGTEXT : NOGROUPSFOUND}
                  </p>
                }                              
                <div className={saveButton}>                  
                  <Button onClick={this.onSubmitHandler} variant="contained" disableRipple className={classNames(margin, updatebutton)}>{SAVETEXT}</Button>
                </div>
              </div>
            </div>
          </Paper>
          <ToastMessages statusMessage={this.state.statusMessage} message={this.state.message} variant={this.state.messageVariant} toastHPosition={this.state.toastHPosition} toastVPosition={this.state.toastVPosition} close={this.handleToast.bind(this)} />
        </div>
      </div>
    )
  }
}

ChangeGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(AddGroupStyles)(memo(ChangeGroup)));