import React from 'react';
import { FormattedMessage as Culture } from 'react-intl';
import appConfig from '../../Environment/environments';
import CryptoJS from 'crypto-js';
import axiosInstance from '../../Components/Shared/Interceptor/interceptor';
import { logout } from '@ccr-main/auth-module';// Provide path
import * as jwt_decode from 'jwt-decode';

export const getImageUrl = (appId, imageType) => {
    imageType = (imageType) ? imageType : "png";
    return `${appConfig.api.blobUrl}${appId}.${imageType}?v=${Math.floor(Math.random() * 1000)}`;
}

export const translation = (id) => {
    return <Culture id={id} />;
}

export const injectIntlTranslation = (intl, id) => {
    return intl.formatMessage({
        id: id
    });
}
export const getSortedData = (data, sort = true) => {
    if (sort) {
        data.sort((a, b) => (a.email.toLowerCase() >= b.email.toLowerCase()) ? 1 : -1)
    }
    else {
        data.sort((a, b) => (a.email.toLowerCase() <= b.email.toLowerCase()) ? 1 : -1)
    }
    data.forEach(element => {
        if (element.lastSigned != null) {
            var lastSigned = new Date(element.lastSigned);
            element.lastSigned = lastSigned.toLocaleString();
        }
    });
    return data;
}

export const formatDate = (data, columnName) => {
    data.forEach(element => {
        if (element[columnName] != null) {
            var actualDate = new Date(element[columnName]);
            element[columnName] = actualDate.toLocaleString();
        }
    });
    return data;
}

export const encryptData = (data) => {
    var key = CryptoJS.enc.Utf8.parse(appConfig.api.encryption.Key);
    var iv = {
        keySize: 128 / 8,
        iv: CryptoJS.enc.Utf8.parse(appConfig.api.encryption.IV),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };

    return encodeURIComponent(CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key, iv));
}

export const downloadImage = (id, imageType) => {
    const imageUrl = getImageUrl(id, imageType);
    const imageName = `${id}.${imageType}`;
    const link = document.createElement('a');
    link.href = imageUrl;
    link.target = "_blank";
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
}

export const updateLogoutTime = async () => {
    try {
        const updateLogtimeResult = await axiosInstance.put(`${appConfig.api.development}/api/users/logout`)
        if (updateLogtimeResult.data) {
            localStorage.removeItem("emailTemplateId")
            logout(appConfig.api.loginConfig);
        }
    }
    catch (error) { }
}

export const validateEmailRegex = (email) => {
    if (email != "") {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !(re.test(email));
    } else {
        return null
    }
}

export const verifyAndGetToken = () => {
    const token = localStorage.getItem('msal.idtoken');
    if(token == '' || token == null || typeof token === 'undefined'){
        logout(appConfig.api.loginConfig);
    }

    return token;
};

export const getIDToken = () => {
    try{
        return jwt_decode(verifyAndGetToken());
    }catch(error){
      console.log(error);
    }    
}
  
export const getUserType = () => {
    const idToken = getIDToken();
    if (idToken) {
        if (idToken.usertype == "external") {
            return 1; // External
        } else if (idToken.usertype == "CarrierOkta") {
            return 0; // Internal
        } else {
            return 2; // HVAC
        }
    } else {
        console.log('getIDToken is null.');
    }
}

// export const logout = () => {
//     localStorage.clear();
//     const msalConfig = {
//         auth: {
//             clientId: appConfig.api.clientID,
//             authority: appConfig.api.authority,
//             postLogoutRedirectUri: appConfig.api.postLogoutRedirectUri,
//             navigateToLoginRequestUrl: false,
//             validateAuthority: false
//         },
//         cache: {
//             cacheLocation: "localStorage",
//             storeAuthStateInCookie: true
//         }
//     };
//     const userAgentApplication = new Msal.UserAgentApplication(msalConfig);
//     userAgentApplication.handleRedirectCallback((error, response) => {
//     });
//     userAgentApplication.logout();
// }
