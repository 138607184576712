import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import SettingsDropDown from './settingsDropdown';
import SettingsChildrenDropDown from './SettingsChildrenDropDown';
import { SAVETEXT, ROOTSETTINGS, SAVESETTINGS, SettingsDrawerStyles, SETTINGSLABEL, LOADINGSETTINGSTEXT, NOSETTINGSTEXT } from '../../../Common/Constants/constants';
import {apiPost,apiGet} from '../Interceptor/apiService';
import { encryptData } from '../../../Common/Utilities/utility';

class SettingsDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      loading: true
    };

    this.selectedAppSettings = {};
    this.deselectedApps = {};
    this.newSelectedPermissions = {};
    this.oldSelectedApps = [];
    this.newSelectedApps = [];
  }

  isObjectEmpty = (objToBeVerified) => {
    return (Object.keys(objToBeVerified).length !== 0)
  }

  updateSettings = (type, appId, settingId, selected, parentId, isDeselected = false, isSaveClicked = false, isAPIcall = false) => {
    if(!isAPIcall && type == "setting"){
      if(!this.newSelectedApps[appId] || this.newSelectedApps[appId == null]){
        this.newSelectedApps[appId] = {};
      }
      if(!this.newSelectedApps[appId].Settings || this.newSelectedApps[appId].Settings == null){
        this.newSelectedApps[appId].Settings = [];
      }

      const index = this.newSelectedApps[appId].Settings.indexOf(settingId);
      if(selected){
        this.newSelectedApps[appId].Settings.push(settingId);      
      } else{
        if (index > -1) { // only splice array when item is found
          this.newSelectedApps[appId].Settings.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }

    if (this.selectedAppSettings[appId] && this.deselectedApps[appId]) {
      const parentID = parentId === null ? settingId : parentId;
      const parentIdFlag = parentId !== null && parentId !== settingId;
      let hierarchyIndex;

      if(this.isObjectEmpty(this.selectedAppSettings[appId].SettingsHierarchy)) {
        hierarchyIndex = this.selectedAppSettings[appId].SettingsHierarchy[parentID];
      }

      if (parentIdFlag) {
        if (hierarchyIndex) {
          const isSettingsHierarchyAvailable = (this.selectedAppSettings[appId].SettingsHierarchy[parentID].indexOf(settingId) > -1)
          !isSettingsHierarchyAvailable && this.selectedAppSettings[appId].SettingsHierarchy[parentID].push(settingId);
        }
        else {
          this.selectedAppSettings[appId].SettingsHierarchy[parentID] = [];
        }
      }

      if (selected) {
        if (type.toLowerCase() === 'app') {
          this.selectedAppSettings[appId].isSelected = true;
          this.deselectedApps[appId].toAddForDeselect = false;
        }
        else {
          const deselectedIndex = this.deselectedApps[appId].Settings.indexOf(settingId);
          const isSettingAvailable = (this.selectedAppSettings[appId].Settings.indexOf(settingId) > -1)
          !isSettingAvailable && this.selectedAppSettings[appId].Settings.push(settingId);
          if (deselectedIndex >= 0) {
            this.deselectedApps[appId].Settings.splice(deselectedIndex, 1);
          }
        }
      }
      else {
        if (type.toLowerCase() === 'app') {
          this.selectedAppSettings[appId].isSelected = false;
          this.deselectedApps[appId].toAddForDeselect = true;
        }
        else {
          this.removeSettingsEntry(appId, settingId, isDeselected, isSaveClicked);
        }
      }
    }
  }

  removeSettingsEntry = (appId, settingId, isDeselected, isSaveClicked) => {
    const index = this.selectedAppSettings[appId].Settings.indexOf(settingId);
    const deselectedIndex = this.deselectedApps[appId].Settings.indexOf(settingId);
    if (index > -1) {
      this.selectedAppSettings[appId].Settings.splice(index, 1);
    }
    if (isDeselected && (deselectedIndex <= -1)) {
      this.deselectedApps[appId].Settings.push(settingId)
      isSaveClicked && this.adjustSelectAndDeselectedSettings(appId, settingId, isDeselected, isSaveClicked);
    }
  }

  adjustSelectAndDeselectedSettings = (appId, settingId, isDeselected, isSaveClicked) => {
    const array = this.selectedAppSettings[appId].SettingsHierarchy[settingId];
    if (array && (array.length > 0)) {
      for (const setting in array) {
        const removeSettingId = array[setting];
        this.removeSettingsEntry(appId, removeSettingId, isDeselected, isSaveClicked);
        const result = this.selectedAppSettings[appId].SettingsHierarchy[removeSettingId] >= 0 && this.selectedAppSettings[appId].SettingsHierarchy[removeSettingId].length > 0;
        if (result) {
          this.adjustSelectAndDeselectedSettings(appId, removeSettingId, isDeselected, isSaveClicked);
        }
      }
    }
  }
  
  deleteEntryInDeletedApps = (deletedEntry, deselectedAppskey) => {
    deletedEntry.Settings.forEach((setting) => {
      const selectedAppData = this.selectedAppSettings[deselectedAppskey];
      const isSettingsHirarchyEmpty = selectedAppData && this.isObjectEmpty(selectedAppData.SettingsHierarchy);
      if (isSettingsHirarchyEmpty) {
        const settingsHierarchy = selectedAppData.SettingsHierarchy[setting];
        settingsHierarchy && selectedAppData.forEach((settingId) => {
          this.removeSettingsEntry(deselectedAppskey, settingId, true, true);
        });
      }
    });
  }

  isPermissionSelected = (type, appId, settingId) => {
    let result = {
      isSelected: false,
      isDeselected: false,
    };
    if(this.selectedAppSettings[appId] && this.deselectedApps[appId]) {
      if (type.toLowerCase() !== 'app')  {
        if (this.selectedAppSettings[appId].Settings.indexOf(settingId) > -1) {
          result.isSelected = true;
        }
        if (this.deselectedApps[appId].Settings.indexOf(settingId) > -1) {
          result.isDeselected = true;
        }
      }
    }
    return result;
  }

  isPermissionSaved = (type, appId, settingId = "") => {
    const { selectedPermissions = [] } = this.props;
    let isPermissionSaved = false;
    const isPermissionSelected = this.isPermissionSelected(type, appId, settingId);
    isPermissionSaved = isPermissionSelected.isSelected;
    if (selectedPermissions && (selectedPermissions.length > 0)) {
      if (type.toLowerCase() === 'app') {
        isPermissionSaved = selectedPermissions[appId].isSelected;
      }
      else {
        isPermissionSaved = (isPermissionSaved || (selectedPermissions[appId].Settings.indexOf(settingId) > -1));
      }
    }
    return isPermissionSaved;
  }

  saveSettingsForGroup = async() => {
    const {onPermissionsSave,groupId,onSave,callSaveApi=true} = this.props;
    let Apps= [];
    let DeletedApps= [];

    for (const deselectedAppskey in this.deselectedApps) {
        let deletedEntry = this.deselectedApps[deselectedAppskey];
        if (deletedEntry.toAddForDeselect)
        {
          if (deletedEntry.Settings.length > 0) {
            this.deleteEntryInDeletedApps(deletedEntry, deselectedAppskey);
          }
        }
        else if (deletedEntry.Settings.length > 0) {
          this.deleteEntryInDeletedApps(deletedEntry, deselectedAppskey);
        }
    }

    for (const key in this.selectedAppSettings) { 
      let entry = this.selectedAppSettings[key];
      if (entry.isSelected && (!this.oldSelectedApps.includes(parseInt(key)) || (entry.Settings.length > 0))) {        
        if (entry.Settings.length > 0) {
          let settings = [];
          if(this.newSelectedApps[key] && this.newSelectedApps[key] != null && this.newSelectedApps[key].Settings){
            settings = this.newSelectedApps[key].Settings;
          }
          Apps.push({ "AppId": key, "Settings": settings })
        }
        else {
          Apps.push({ "AppId": key });
        }
      }
    }

    for (const unSelectedAppskey in this.deselectedApps) {
      let deletedEntry = this.deselectedApps[unSelectedAppskey];
      if (deletedEntry.toAddForDeselect) {
        if (deletedEntry.Settings.length > 0) {
          DeletedApps.push({ "AppId": unSelectedAppskey, "Settings": deletedEntry.Settings })
        } else {
          DeletedApps.push({ "AppId": unSelectedAppskey });
        }
      } else if (deletedEntry.Settings.length > 0) {
        DeletedApps.push({ "AppId": unSelectedAppskey, "Settings": deletedEntry.Settings });
      }
    }
    
    if (callSaveApi) {
      const obj = {
        GroupId: groupId,
        Apps: Apps,
        DeletedApps: DeletedApps
      };
      const savePermissionsObj = JSON.stringify(obj);
      const {data: {isSavedSuccesfully,isChildAvailable}} = await apiPost(SAVESETTINGS,savePermissionsObj,this.saveAppSettingsFail);
      this.selectedAppSettings = {};
      this.deselectedApps = {};
      this.setState({
        children: [],
        loading: true
      });
      onSave(isSavedSuccesfully, (isSavedSuccesfully) ? isChildAvailable : false, obj);
    }
    else {
      onPermissionsSave && onPermissionsSave(Apps,this.selectedAppSettings);
    }
  }

  saveAppSettingsFail = () => {
    this.props.onSave(false, false, []);
  }

  getRootSettingsForGroup = async(groupId) => {
    const {data: {appChilds}} = groupId ? await apiGet(`${ROOTSETTINGS}${encryptData(groupId).toString()}`,this.getRootSettingsFail) :
    await apiGet(`${SAVESETTINGS}`,this.getRootSettingsFail);
    this.selectedAppSettings = [];
    const appChildren = appChilds.map((setting) => {
      let isSelected = setting.isSelected;
      if(this.props.unSelectByDefault) {
        isSelected = this.isPermissionSaved("app", setting.appId);
      }
      if(isSelected)
        this.oldSelectedApps.push(setting.appId);
      this.selectedAppSettings[setting.appId] = { "isSelected": isSelected, "Settings": [], "SettingsHierarchy": {} };
      this.deselectedApps[setting.appId] = { "toAddForDeselect": false, "Settings": [] };
      setting.isSelected = isSelected;
      return setting;
    });
    this.setState({
      children: appChildren,
      loading: false
    });
  }

  getRootSettingsFail = () =>
    this.setState({
      loading: false
    });
  
  showRootSettings(){
    if (this.state.children.length > 0) {
      const {groupId,isEditable,unSelectByDefault=false,headerLabel,groupLevel} = this.props;
      this.props.getPermissions && this.props.getPermissions(this.state.children)
      if(groupLevel === "Default") {
        return (
          this.state.children.map((ChildGroup) => (
            <SettingsDropDown
              unSelectByDefault={unSelectByDefault}
              isPermissionSaved={this.isPermissionSaved}
              TreeLevel={0}
              groupId={groupId}
              type="app"
              saveSettings={this.updateSettings}
              isEditable={isEditable}
              groupData={ChildGroup}
              appId={ChildGroup.appId}
              name={ChildGroup.name}
              isPermissionSelected={this.isPermissionSelected}
            />
          ))
        )
      }
      else{
        return (
          this.state.children.map((ChildGroup) => (
            <SettingsChildrenDropDown
              unSelectByDefault={unSelectByDefault}
              isPermissionSaved={this.isPermissionSaved}
              TreeLevel={0}
              groupId={groupId}
              type="app"
              saveSettings={this.updateSettings}
              isEditable={isEditable}
              groupData={ChildGroup}
              appId={ChildGroup.appId}
              name={ChildGroup.name}
              isPermissionSelected={this.isPermissionSelected}
            />
          ))
        )
      }
    }
  }

  closeSettingsDialog = () => {
    this.selectedAppSettings = {};
    this.deselectedApps= {};
    this.props.onClose();
    this.setState({
      children: [],
      loading: true
    });
  }

  render() {
    const { classes, headerLabel = SETTINGSLABEL, open, groupId, isEditable, offset } = this.props;
    const {children: {length}, loading} = this.state;
    const {paperRoot,list,editTitle,editGroupTitle,closeIcon,rootSettings,disableRootSettings,
      loadingTitle,settingsSaveButton,margin,updatebutton, filterPaperRoot} = classes;
    if (length <= 0 && open && loading) {
      this.getRootSettingsForGroup(groupId);
    }
    
    return (
      <Drawer anchor="right" open={open} onClose={this.closeSettingsDialog}
        ModalProps={{ disableBackdropClick: false, disableEscapeKeyDown: false }}
        PaperProps={{
          classes: {
            root: (offset)? filterPaperRoot: paperRoot
          }
        }}
      >
      <div className={list} role="presentation">
          <div className={editTitle}>
            <p className={editGroupTitle}>{headerLabel}
              <span className={closeIcon} onClick={this.closeSettingsDialog}>
                <EditClose></EditClose>
              </span>
            </p>
          </div>
          {(length > 0)
            ?
            <div
              id="permissionsRoot"
              className={(isEditable) ? rootSettings : disableRootSettings}>
              {this.showRootSettings()}
            </div>
            :
            <p className={loadingTitle}>
              {(loading) ? LOADINGSETTINGSTEXT : NOSETTINGSTEXT}
            </p>
          }

          {(isEditable && length > 0 && !loading) &&
            <div className={settingsSaveButton}>
              <Button
                id="permissionsSave"
                variant="contained"
                onClickCapture={this.saveSettingsForGroup}
                disableRipple
                className={classNames(margin, updatebutton)}>
                {SAVETEXT}
              </Button>
            </div>
          }
        </div>
      </Drawer>
    )
    }
}

export default withStyles(SettingsDrawerStyles, { withTheme: true })(SettingsDrawer);