import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { injectIntlTranslation } from '../../Common/Utilities/utility';
import { intlShape, injectIntl } from "react-intl";
import {apiPost} from '../Shared/Interceptor/apiService';
import { ReactComponent as EditClose } from '../../Assests/Images/EditGroupClose.svg';
import { AddGroupStyles, APPLYTEXT, FILTERERROR, GROUPSEARCH, PARENTGROUP, CHANGEFILTER, ADDFILTERBUTTON, FILTERS, USERNAME, PERMISSION} from '../../Common/Constants/constants';
import { setParentGroupFilter, setUsersSelection, setUserPermissions } from '../../redux/Actions/filterAction';

class AddFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: ""
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selectedItem.value !== this.props.selectedItem.value || prevProps.selectedValues !== this.props.selectedValues || prevProps.usersSaved !== this.props.usersSaved || prevProps.selectedPermissions !== this.props.selectedPermissions) {
      this.validateFilter();
    }
  }

  onSubmitHandler = async(e) => {
    e.preventDefault();
    let userIdsList = [];
    if(this.props.selectedValues){
      userIdsList = this.props.selectedValues.map(user => user.userId);
    } 
    if(this.props.selectedItem.value || this.props.selectedValues.length > 0 || this.props.selectedPermissions.length > 0) {
      const obj = {
        Type: "2",
        IsAllInfo: true,
        PGroupId: this.props.selectedItem.value,
        UserIds: userIdsList,
        Apps: this.props.selectedPermissions
      }
      const updatedObj = JSON.stringify(obj);
      const {data} = await apiPost(GROUPSEARCH, updatedObj);
      this.props.setParentGroupFilter(this.props.selectedItem);
      this.props.setUsersSelection(this.props.selectedValues);
      this.props.setUserPermissions(this.props.selectedPermissions, this.props.selectedApps);
      this.filterSuccessCB(data);
    }
    else {
      this.validateFilter();
    }
  }

  filterSuccessCB(data) {
    this.props.setFilteredData(data);
  }

  validateFilter = () => {
    this.setState({
      error: ((this.props.selectedItem.value || this.props.selectedValues.length > 0 || this.props.selectedPermissions.length > 0) ? "" : FILTERERROR)
    });
  }

  handleDelete = (type) => {
    this.props.onClearFilterSelection(type);
  };

  getTranslatedString = (constantName) => {
    return injectIntlTranslation(this.props.intl, constantName)
  }

  render() {
    const { classes, intl } = this.props;
    return (
      <div className={classes.root}>
        <div>
          <Paper square={true} classes={{ root: classes.paperSize, elevation1: classes.editGroupPaper }}>
            <div className={classes.editTitle}>
              <p className={classes.editGroupTitle}>
                {this.getTranslatedString(FILTERS)}
                <span className={classes.closeIcon} onClick={this.props.onClose}>
                  <EditClose></EditClose>
                </span>
              </p>
            </div>
            <div className={classes.editContent}>
              <form className={classes.container} onSubmit={this.onSubmitHandler} noValidate>
                 <span className="filterSelect">
                  <div className="form-group">
                    <label className="label-group"> <span className="parentGroupName">{PARENTGROUP}</span></label>
                    <div className="addGroupFilter">
                      <div>
                      <input type="button" className="addFilterButton" value={this.getTranslatedString(this.props.selectedItem.label ? CHANGEFILTER : ADDFILTERBUTTON)} onClick={() => this.props.openParallelDrawer && this.props.openParallelDrawer()}></input>
                      </div>
                      {this.props.selectedItem.label && 
                        <Chip label={this.props.selectedItem.label} onDelete={()=>this.handleDelete("parentGroup")} className={classes.chip} />
                      }
                    </div>
                    <label className="label-group"> <span className="parentGroupName">{USERNAME}</span></label>
                    <div className="addGroupFilter">
                      <div>
                      <input type="button" className="addFilterButton" value={this.getTranslatedString(this.props.selectedValues.length > 0 ? CHANGEFILTER : ADDFILTERBUTTON) } onClick={() => this.props.onAddUserClick && this.props.onAddUserClick()}></input>
                      </div>
                      { this.props.selectedValues.length > 0 ?
                       (this.props.selectedValues.length === 1 ? 
                       <Chip label={this.props.selectedValues[0].name} onDelete={()=>this.handleDelete("userName")} className={classes.chip} /> :
                       <Chip label={this.props.selectedValues.length + " users"} onDelete={()=>this.handleDelete("userName")} className={classes.chip} />) 
                       : ""
                      }
                    </div>
                    <label className="label-group"> <span className="parentGroupName">{PERMISSION}</span></label>
                    <div className="addGroupFilter">
                      <div>
                      <input type="button" className="addFilterButton" value={this.getTranslatedString(this.props.permissionCount > 0 ? CHANGEFILTER : ADDFILTERBUTTON) } onClick={() => this.props.onAddPermissions && this.props.onAddPermissions()}></input>
                      </div>
                      {
                      this.props.permissionCount > 0 ?
                       (this.props.permissionCount === 1 ?   
                       <Chip label={this.props.permissionName} onDelete={()=>this.handleDelete("permission")} className={classes.chip} /> :
                       <Chip label={this.props.permissionCount + " Permissions"} onDelete={()=>this.handleDelete("permission")} className={classes.chip} />) 
                       : ""
                      } 
                    </div>
                  </div>
                </span>
                <div className="parentGroupError">{this.state.error}</div>
                <div className={classes.saveButton}>
                  <Button type="submit" variant="contained" disableRipple className={classNames(classes.margin, classes.updatebutton)}>{APPLYTEXT}</Button>
                </div>
              </form>
            </div>
          </Paper>        
        </div>
      </div>
    )
  }
}

AddFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(withRouter(connect(null, {setParentGroupFilter, setUsersSelection, setUserPermissions})(withStyles(AddGroupStyles)(AddFilter))));