import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ToastMessages from '../../Shared/Toast/ToastMessages';
import { ReactComponent as EditClose } from '../../../Assests/Images/EditGroupClose.svg';
import SendInvitation from './sendInvitation';
import RightDrawer from "../RightDrawer/RightDrawer";
import { UserInviteStyles, USERADD } from '../../../Common/Constants/constants';

const useStyles = makeStyles(UserInviteStyles, { withTheme: true });

export default function UserInvite(props) {  
  const {statusMessage, message, messageVariant, setStatusMessage} = props;
  const classes = useStyles();

  const handleToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setStatusMessage(false);
  }

  // const openEmailPreview = () =>
  //   setPreviewDrawer({ open: true});

  // const closeEmailPreview = () =>
  //   setPreviewDrawer({ open: false, offset: "0px" });

  const sideList = (side) => (
    <div>
        <ToastMessages
        statusMessage={statusMessage}
        message={message}
        variant={messageVariant}
        toastHPosition={"center"}
        toastVPosition={"top"}
        close={handleToast} />
    <div
      className={classes.list}
      role="presentation"
    >
      <div className={classes.editTitle}>
        <p className={classes.editGroupTitle}>{USERADD}
          <span className={classes.closeIcon} onClick={props.onClose}>
            <EditClose></EditClose>
          </span>
        </p>
      </div>
      <SendInvitation
        inviteHandleClose={props.eventHandle}
        Id={props.ID}
        isManager={props.isManager}
        historyid={props.historyid}
        loggedInUserType={props.loggedInUserType}
        isAppInvite={props.isAppInvite}
        loggedInUserName={props.loggedInUserName}
        groupName={props.groupName}
      />
    </div>
    </div>
  );

  return (
    <div>
      <RightDrawer  customProps={{ width: "345px" }} open={props.open}>
          {sideList('right')}
        </RightDrawer>
     
      {/* <Drawer anchor="right" open={props.open} onClose={props.onClose}
        ModalProps={{ disableBackdropClick: false, disableEscapeKeyDown: false }}
        PaperProps={{
          classes: {
            root: classes.paperRoot
          }
        }}
      >
        {sideList('right')}
      </Drawer>
      
      {previewDrawer.open && (
      <Drawer anchor="right" open={true} onClose={props.onClose}
        ModalProps={{ disableBackdropClick: false, disableEscapeKeyDown: false }}
        PaperProps={{
          classes: {
            root: classes.paperRoot
          }
        }}
      >
        <EmailTemplatePreview templateData={templateDetails}></EmailTemplatePreview>
      </Drawer>
      )} 
      */}
   
    </div>
  );
}
