import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { OptionMenuEditEmailDialogStyles, CONFIRMATIONTEXT, CANCELTEXT } from '../../../Common/Constants/constants';

const useStyles = makeStyles(OptionMenuEditEmailDialogStyles);

function UpdateEmailPopUp(props) {
  const classes = useStyles();
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function passwordchange(e) {
    var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,64})");
    if (e.target.value == null || e.target.value == "") {
      setPasswordError("Password is required");
    }
    else if (regix.test(e.target.value) == false) {
      setPasswordError(`Password must be between 8 and 64 characters.
        The password must have at least 3 of the following:
        <div>&nbsp;-a lowercase letter</div>
        <div>&nbsp;-a uppercase letter</div>
        <div>&nbsp;-a digit</div>
        &nbsp;-a symbol`);
    }
    else {
      setPasswordError("");
    }
    setPassword(e.target.value);

  }

  function confirmPasswordchange(e) {
    var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,64})");
    if (e.target.value == null || e.target.value == "") {
      setConfirmPasswordError("Confirm Password is required");
    }
    else if (regix.test(e.target.value) == false) {
      setConfirmPasswordError(`Password must be between 8 and 64 characters.
        The password must have at least 3 of the following:
        <div>&nbsp; -a lowercase letter</div>
        <div>&nbsp; -a uppercase letter</div>
        <div>&nbsp; -a digit</div>
        &nbsp; -a symbol`);
    }
    else {
      setConfirmPasswordError("");
    }
    setConfirmPassword(e.target.value);
  }
  function verification() {
    var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,64})");
    let isVerified = true;
    if (password == null || password == "") {
      setPasswordError("Password is required");
      isVerified = false;
    }
    else if (regix.test(password) == false) {
      setPasswordError(`Password must be between 8 and 64 characters.
      The password must have at least 3 of the following:
      <div>&nbsp; -a lowercase letter</div>
      <div>&nbsp; -a uppercase letter</div>
      <div>&nbsp; -a digit</div>
      &nbsp;-a symbol`);
      isVerified = false;
    }
    else {
      setPasswordError("");
    }
    if (confirmPassword == null || confirmPassword == "") {
      setConfirmPasswordError("Confirm Password is required");
      isVerified = false;
    }
    else if (password != "" && confirmPassword != "" && password != confirmPassword) {
      setConfirmPasswordError("Password and Confirm password must be same");
      isVerified = false;
    }
    else if (regix.test(confirmPassword) == false) {
      setConfirmPasswordError(`Password must be between 8 and 64 characters.
      The password must have at least 3 of the following:
      <div>&nbsp; -a lowercase letter</div>
      <div>&nbsp; -a uppercase letter</div>
      <div>&nbsp; -a digit</div>
      &nbsp; -a symbol`);
      isVerified = false;
    }
    else {
      setConfirmPasswordError("");
    }
    if (isVerified) {
      props.onSaveClick(password);
    }
  }
  return (
    <div>
      <Dialog
        open={props.opennow}
        onClose={props.onCloseEvent}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: classes.root }}
      >
        <DialogTitle id="responsive-dialog-title"> {props.dialogHead}</DialogTitle>
        <DialogContent id="addbg">
          <DialogContentText>
            <p className={classes.editemailtitle}>{props.dialogContent}</p>
            <p className={classes.editemaildisclaimer}> {(props.dialogDisclaimer) ? props.dialogDisclaimer : CONFIRMATIONTEXT}</p>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            onChange={passwordchange}
          />
          {/* {passwordError && */}
          <div className={classes.errorText} dangerouslySetInnerHTML={{ __html: passwordError }} />
          <TextField
            autoFocus
            margin="dense"
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            fullWidth
            onChange={confirmPasswordchange}
          />
          <div className={classes.errorText} dangerouslySetInnerHTML={{ __html: confirmPasswordError }} />
        </DialogContent>
        <DialogActions id="dialogbutton">
          <>
            {password && confirmPassword && !passwordError && !confirmPasswordError &&
              <Button color="secondary" variant="outlined" onClick={verification}>
                {props.dialogButtonText}
              </Button>}
            <Button color="secondary" onClick={props.onCloseEvent} variant="outlined" >
              {CANCELTEXT}
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UpdateEmailPopUp.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(UpdateEmailPopUp);