const version = '1.4.0.0';
const postLogOutUri = 'https://dev-appshub.coolsystemsselect.com';
const apiUri = 'https://devapi-appshub.coolsystemsselect.com';
const authModuleVer = '2.1.9';
const storageBaseUri = 'https://staticfilesappshubdev.coolsystemsselect.com/';

export const appConfig = {
  api: {
    development: apiUri,
    postLogoutRedirectUri: postLogOutUri,
    encryption: {
      IV: "8080808080808080",
      Key: "8080808080808080"
    },
    version: version,
    authVersion: authModuleVer,
    authSettings: {
      azureIss: "Azure Iss",
      awsIss: "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_OhoDEPXUA"
    },
    blobUrl: storageBaseUri + 'logo/',
    emailUrl: storageBaseUri + 'email/',
    storageUrl: storageBaseUri,
    loginConfig: {
      apiUrl: apiUri + '/',
      uiUrl: postLogOutUri,
      postLogOutUri: postLogOutUri,
      redirectAppUri: "https://devauth-appshub.coolsystemsselect.com",
      appClientId: '4lvfhehheh6cm5rb1ge6plpoo3'
    }
  }
}

export default appConfig
