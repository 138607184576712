import React, {useEffect} from 'react';
import useTemplateTabStyles from './TemplateTabStyles';
import { SAVETEXT, APPCONFIGTEMPLATE,CREATEDYNAMICSCREENAPI,IMGSIZEERROR,IMGSIZEERRORFORAPPTEMPLATE,TemplateTabStyles,SUBMITBUTTONERRORMESSAGE,DEVELOPERDESCRIPTION,STEP1DESCRIPTION,
  APPCLIENTID,BACKGROUNDIMAGE,APPIMAGE,FONTCOLOR,SETDEFAULT,STEP2DESCRIPTION,CODESNIPPET,APPCLIENTIDDESCRIPTION,HELPTEXT,IMGTYPEERROR,BGIMGSIZEERROR,FONTERRORMESSAGE,APPTEMPLATEESUCCESS
} from '../../../../Common/Constants/constants';
import Button from '@material-ui/core/Button';
import CopySnippet from '../CopySnippet/CopySnippet';
import axiosInstance from '../../../Shared/Interceptor/interceptor';
import axios from 'axios';
import appConfig from '../../../../Environment/environments';
import CustomTextField from '../../../Groups/CustomTextField';
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from "@material-ui/core/CardContent";
import Typography from '@material-ui/core/Typography';
import ToastMessages from '../../../Shared/Toast/ToastMessages';
import PropTypes from 'prop-types';
const styles = TemplateTabStyles;

 function TemplateTab(props) {
  const [value, setValue] = React.useState(0);
  const [fontvalue, setFontValue] = React.useState('');
  const [bgImage, setBgImage] = React.useState('');
  const [appImage, setAppImage] = React.useState('');
  const [appImageType, setAppImageType] = React.useState('');
  const [bgImageType, setBgImageType] = React.useState('');
  const [appId, setAppId] = React.useState('');
  const [fileName, setFileName] = React.useState('No file chosen');
  const [images, setImages] = React.useState({});
  const [bgFileName, setbgFileName] = React.useState('No file chosen');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [bgErrorMessage, setBgErrorMessage] = React.useState('');
  const [submitErrorMessage, setSubmitErrorMessage] = React.useState('');
  const [fontErrorMessage, setFontErrorMessage] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messageVariant, setMessageVariant] = React.useState("");

  const { developerDesc, cancelBtn, normalBtn, step1, step2, step3, appImageStyle, validError,
    span_appImage,span_bgImage,bgImageStyle,span_AppClienId,span_HelpText, span_CodeSnippet, 
    mainDiv, leftDiv, card, cardContent, cardContentLoad } = useTemplateTabStyles();
  const { appUrl, Id, classes, appClientId } = props;
  
  useEffect(() => {
    if(props.appClientId){
      let baseUrl = `${appConfig.api.storageUrl}apps/` + props.appClientId + '/app-template.json?v='; 
      axios.get(baseUrl + Math.floor(Math.random() * 100))
      .then(function (response) {
        if(response != null) {
          response = response.data;
          setImages({
            Background: (response == null || response.bgImage == null || response.bgImage == '') ?
            `${appConfig.api.storageUrl}apps/CarrierBG.png` : response.bgImage +"?v="+ Math.floor(Math.random() * 100),
            App: (response == null || response.appImage == null || response.appImage == '') ? 
            `${appConfig.api.storageUrl}apps/CarrierLogo.png` : response.appImage +"?v="+ Math.floor(Math.random() * 100)          
          });
          setFontValue((response.color == null) ? "" : response.color);
        }
      }).catch(error => {
        setImages({
          Background: `${appConfig.api.storageUrl}apps/CarrierBG.png`,
          App: `${appConfig.api.storageUrl}apps/CarrierLogo.png`
        });
        setFontValue("#0d2c75");
        console.log(error);
      });
    }else{
      setImages({
        Background: `${appConfig.api.storageUrl}apps/CarrierBG.png`,
        App: `${appConfig.api.storageUrl}apps/CarrierLogo.png`
      });
    }
  }, []);

  const clearFileContent = (e) => {
    e.target.value = '';
  }

  const handleToast = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setStatusMessage(false);
  }

  const onAppImageUpload = (e) => {
    setFileName('No file chosen')
    setAppImage(""); 
    resetErrorMessages()
    var fileSizeCheck = 0;
    var fileTypeCheck = false;
    fileSizeCheck = (e.target.files[0]) ? (e.target.files[0].size <= 30000) : false;
    fileTypeCheck = (e.target.files[0]) ? ((e.target.files[0].type === "image/jpeg") || (e.target.files[0].type === "image/png") || (e.target.files[0].type === "image/bmp")|| (e.target.files[0].type === "image/jpg")|| (e.target.files[0].type === "image/svg+xml")) : false;
    let reader = new FileReader();
    if (fileSizeCheck && fileTypeCheck) {
      setFileName(e.target.files[0].name)
      setAppImageType(e.target.files[0].type.split('/')[1]==="svg+xml"?e.target.files[0].type.split('/')[1].split("+")[0]:e.target.files[0].type.split('/')[1])
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function () {
        setAppImage(reader.result.split(',')[1]); 
      }
    }

    else if (fileSizeCheck === false) {
      setAppImage(""); 
      setAppImageType('')
      setErrorMessage(IMGSIZEERROR)
    }
    else if (fileTypeCheck === false) {
      setAppImage(""); 
      setAppImageType('')
      setErrorMessage(IMGTYPEERROR)
    }
  }

  const onBackgroundUpload = (e) => {
    resetErrorMessages()
    setBgImage(""); 
    var fileSizeCheck = 0;
    var fileTypeCheck = false;
    fileSizeCheck = (e.target.files[0]) ? (e.target.files[0].size < 5000000) : false;
    fileTypeCheck = (e.target.files[0]) ? ((e.target.files[0].type === "image/jpeg") || (e.target.files[0].type === "image/png") || (e.target.files[0].type === "image/bmp")|| (e.target.files[0].type === "image/jpg")|| (e.target.files[0].type === "image/svg+xml")) : false;
    let reader = new FileReader();
    if (fileSizeCheck && fileTypeCheck) {
    reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = function () {
        setBgImage(reader.result.split(',')[1]); 
      }; 
      setbgFileName(e.target.files[0].name)   
      setBgImageType(e.target.files[0].type.split('/')[1]==="svg+xml"?e.target.files[0].type.split('/')[1].split("+")[0]:e.target.files[0].type.split('/')[1])
    }
    else if (fileSizeCheck === false) {
      setBgImage("");
      setBgImageType('') 
      setBgErrorMessage(BGIMGSIZEERROR)
    }
    else if (fileTypeCheck === false) {
      setBgImage(""); 
      setBgImageType('')
      setBgErrorMessage(IMGTYPEERROR)
    }
  }

  const clearAllFields=()=>{
  setBgImage('')
  setAppImage('')
  setFontValue('')
  setAppImageType('')
  setBgImageType('')
  }

  const resetErrorMessages=()=>
  {
  setErrorMessage('')
  setBgErrorMessage('')
  setSubmitErrorMessage('')
  setFontErrorMessage('')
  }

  const resetErrorFields=()=>{
  setFileName('No file chosen')
  setbgFileName('No file chosen')
  setErrorMessage('')
  setBgErrorMessage('')
  setSubmitErrorMessage('')
  setFontErrorMessage('')
  }

  const isValidHexaCode=()=> {
  // Regex to check valid
  // hexadecimalColor_code 
  let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

  if (regex.test(fontvalue) == true || fontvalue==='') {
    setFontErrorMessage('')
  }
  else {
      setFontErrorMessage(FONTERRORMESSAGE)
      setFontValue('')
  }
  }

  const fieldChangeHandler=(targetValue)=>{
    resetErrorMessages()
    if(targetValue!=='')
    {
      setFontValue(targetValue)
    }
      else
      setFontValue('')
  }

  const saveTemplate = () => {
    resetErrorFields();
    if(fontvalue  || appImage || bgImage) {
      let obj = {
        "id":Id ,
        "appId": appClientId != ''? appClientId : '',
        "color": fontvalue,
        "appImage": appImage,
        "bgImage": bgImage,
        "bgImageType":bgImageType,
        "appImageType":appImageType
      };
      let data = JSON.stringify(obj);
      let request = axiosInstance.post(`${appConfig.api.development}${CREATEDYNAMICSCREENAPI}`, data,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      request.then(res => {
        setAppId(res.data);
        setImages(prevData => {
          return {
            ...prevData,
            Background: (bgImage != null && bgImage != '')? `${appConfig.api.storageUrl}apps/${res.data}/bg-image.${bgImageType}?v=${+ Math.floor(Math.random() * 100)}` : prevData.Background,
            App: (appImage != null && appImage != '')? `${appConfig.api.storageUrl}apps/${res.data}/app-image.${appImageType}?v=${+ Math.floor(Math.random() * 100)}` : prevData.App
          }
        });
        
        setStatusMessage(true);
        setMessage(APPTEMPLATEESUCCESS);
        setMessageVariant("success");
      });        
    clearAllFields();
    } else {
      if(bgErrorMessage ===''  && errorMessage===''  && fontErrorMessage==='')
        setSubmitErrorMessage(SUBMITBUTTONERRORMESSAGE)
    }
  }

  const setDefaultTemplate = () => {
    resetErrorFields();
    clearAllFields();
    let obj = {
      "id":Id ,
      "appId": appClientId!=''?appClientId:'',
      "color": '',
      "appImage": '',
      "bgImage": '',
      "bgImageType":'',
      "appImageType":'',
      "isDefault":true
    };
    
    let data = JSON.stringify(obj);
    let request = axiosInstance.post(`${appConfig.api.development}${CREATEDYNAMICSCREENAPI}`, data,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    request.then(res => {
      setImages({
        Background: `${appConfig.api.storageUrl}apps/CarrierBG.png`,
        App: `${appConfig.api.storageUrl}apps/CarrierLogo.png`
      });
      setAppId(res.data);
      setStatusMessage(true);
      setMessage(APPTEMPLATEESUCCESS);
      setMessageVariant("success");
    });
  }

  return (
    <div className={mainDiv}>
      <div className={developerDesc}>{DEVELOPERDESCRIPTION}</div>
      <div>
        <div className={leftDiv}>
          <div className={step1}><b>Step1</b>: {STEP1DESCRIPTION}</div>
          <div className={developerDesc, step1}>
            <span>
            {APPCLIENTID}
            </span>
            <span className={span_AppClienId}>
            {(appId||appClientId)!=''?(appId||appClientId):"Not created yet. It creates when you select the logo/color and save."}
            </span>
          </div>
          <div className={developerDesc, step1}>
            <span>
            {BACKGROUNDIMAGE}
            </span>
            <span className={span_bgImage}>
              <input type='file' id="file"  className={bgImageStyle} onChange={(e) => onBackgroundUpload(e)} onClick={(e) => clearFileContent(e)} />
            </span>
            <span >{bgFileName}</span>
            <div className={validError}>{bgErrorMessage}</div>
          </div>
          <div className={developerDesc, step1}>
            <span>
            {APPIMAGE}
            </span>
            <span className={span_appImage}>
              <input type='file' id="file"  className={appImageStyle} onChange={(e) => onAppImageUpload(e)} onClick={(e) => clearFileContent(e)}  />
            </span>
            <span >{fileName}</span>
            <div className={validError}>{errorMessage}</div>
          </div>
          <div className={developerDesc, step1}>
            <span >{FONTCOLOR}</span>
            <span>
              <CustomTextField  classes={classes} name={fontvalue} label="Font Color:" nameChangeHandler={(e) => fieldChangeHandler(e.target.value)} validateName={isValidHexaCode}  />
            </span>
            <span className={span_HelpText}>{HELPTEXT}</span>
            <div className={validError}>{fontErrorMessage}</div>
          </div>
          <div className={developerDesc, step1}>
            <Button className={` ${normalBtn}`} onClick={saveTemplate}>
                {SAVETEXT}
            </Button>
            {((appClientId || appId)&&
          <Button className={` ${cancelBtn}`} onClick={setDefaultTemplate}>
              {SETDEFAULT}
            </Button> 
            )} 
            <div className={validError}>{submitErrorMessage}</div>      
          </div>
          <div className={step2}>
            <div><b>Step2</b>{STEP2DESCRIPTION}</div>
            <div className={span_CodeSnippet}>{CODESNIPPET}</div>
            <div className={step3}>
              <CopySnippet copyCode={(appId||appClientId)!=''?APPCONFIGTEMPLATE.replace("@appClientId",(appId||appClientId)).replace("appUrl",appUrl):APPCONFIGTEMPLATE.replace("appUrl",appUrl)} />
            </div>
          </div> 
        </div>        
        <div style={{ paddingTop: "32px" }}>
          <Card sx={{ maxWidth: 100 }} className={card}>
          {(images && images.Background) ? <CardMedia
                          component="img"
                          height= "104px"
                          width="160px"
                          image={images.Background}/> : 
                        <div className={cardContentLoad}>Loading...</div> }           
            <CardContent className={cardContent}>
              Background Image
            </CardContent>
          </Card>
          <Card sx={{ maxWidth: 100 }} className={card}>
              {(images && images.App) ? <CardMedia
                          component="img"
                          height= "104px"
                          width="160px"
                          image={images.App}/> : 
                        <div className={cardContentLoad}>Loading...</div> }     
            <CardContent className={cardContent}>
              App Image
            </CardContent>
          </Card>
        </div>
      </div>

      <ToastMessages 
        statusMessage={statusMessage}
        message={message}
        variant={messageVariant}
        toastHPosition={'center'}
        toastVPosition={'top'}
        close={handleToast} />
    </div>
  );
}
TemplateTab.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default  withStyles(styles)(TemplateTab)


